import * as twgl from 'twgl.js'
import anime from 'animejs'

export default class HomeKv {
  constructor() {
    this.timeline = null;
    this.item = {
      fade: 0,
      ringS: 0,
      ringC: 0,
      ringScale: 0.1
    }
    this.setAnime()
  }

  init() {
    this.loadShader([
      '/shader/vs.vert',
      '/shader/fs.frag',
    ])
    .then((shaders) => {
      const vs = shaders[0];
      const fs = shaders[1];
      this.setRender(vs, fs)
    });
  }

  seekEndAnime() {
    this.timeline.seek(this.timeline.duration)
  }

  setAnime() {
    this.timeline = anime.timeline({
      targets: this.item,
      autoplay: false,
      loop: false,
      begin: function(anim) {
        document.querySelector("#canvas").style.backgroundColor = 'transparent'
        anime.set(['#philosophy', '#whoWeAre', '#news', '#members'], {
          opacity: 0
        })
      },
      complete: () => {
        anime.set(['#philosophy', '#whoWeAre', '#news', '#members'], {
          opacity: ''
        })
      }
    })
    .add({
      fade: [0, 1],
      delay: 600,
      easing: 'easeInOutQuad',
      duration: 1000,
    })
    .add({
      ringS: [0, 0.7],
      ringC: [0, 0.8],
      easing: 'easeInOutExpo',
      duration: 1500,
    })
    .add({
      ringS: 0,
      ringC: 0,
      easing: 'easeInOutBack',
      duration: 1000,
    }, '+=1500')
    .add({
      ringScale: 2.5,
      easing: 'easeInOutQuad',
      duration: 1000,
    }, '+=800')
    .add({
      targets: '#canvas',
      opacity: [1, 0],
      easing: 'easeInOutQuad',
      duration: 1000,
      complete: () => {
        document.querySelector("#canvas").style.display = 'none'
      }
    }, '-=800')
    .add({
      targets: '.opSkip',
      opacity: 0,
      duration: 300,
      easing: 'easeOutBack',
      complete: function(anim) {
        document.querySelector('.opSkip').style.display = 'none'
      }
    }, '-=1000')
    .add({
      targets: '.js-kvG',
      duration: 800,
      opacity: [0, 1],
      easing: 'easeInQuart',
    })
    .add({
      targets: 'header',
      duration: 600,
      opacity: [0, 1],
      easing: 'easeInOutQuad',
    })
    .add({
      targets: '.navi > .list',
      duration: 400,
      opacity: [0, 1],
      translateX: [50, 0],
      easing: 'easeOutBack',
      delay: function(el, i, l) {
        return (i + 1) * 100;
      },
    })
    .add({
      targets: '.js-kvScroll',
      duration: 600,
      opacity: [0, 1],
      easing: 'easeInOutQuad',
    })
    .add({
      targets: '.line-drawing-demo #lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 500,
      delay: function(el, i) {
        return i * 150
      },
    }, '-=1600')
    .add({
      targets: '.line-drawing-demo #lines path',
      strokeDashoffset: function(el, i) {
        return -(anime.setDashoffset(el))
      },
      easing: 'easeInOutSine',
      duration: 400,
      delay: function(el, i) {
        return i * 150
      },
    }, '-=400')
    .add({
      targets: ['.line-drawing-demo #text01 path', '.line-drawing-demo #text02 path', '.line-drawing-demo #text03 path'],
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 200,
      delay: function(el, i) {
        return i * 100
      },
    }, '-=2000')
    .add({
      targets: ['.line-drawing-demo #text01 path', '.line-drawing-demo #text02 path', '.line-drawing-demo #text03 path'],
      fill: ['', '#FFFFFF'],
      easing: 'easeInOutSine',
      duration: 200,
      delay: function(el, i) {
        return i * 100
      },
    }, '-=5500')

    // .add({
    //   fade: 0,
    //   easing: 'easeInOutQuad',
    //   duration: 1000,
    // })
  }


  setRender(vs, fs) {

    const gl = document.querySelector("#canvas").getContext("webgl");
    const programInfo = twgl.createProgramInfo(gl, [vs, fs]);

    const arrays = {
      position: [-1, -1, 0, 1, -1, 0, -1, 1, 0, -1, 1, 0, 1, -1, 0, 1, 1, 0],
    };
    const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);

    const textures = twgl.createTextures(gl, {
      // a power of 2 image
      heightTexture: {src: "./images/G.png", mag: gl.NEAREST},
    });

    let s = [1, 1];
    let mouse = [-2, -2];
    /*const resize = ()=> {
      if(gl.canvas.width > gl.canvas.height) {
        s = [gl.canvas.width / gl.canvas.height, 1]
      } else {
        s = [1, gl.canvas.height / gl.canvas.width]
      }
    }

    window.addEventListener('resize', resize);

    // マウスが動いたことに対するイベント処理
    window.addEventListener('mousemove', (evt) => {
      const x = (evt.clientX / gl.canvas.width) * 2.0 - 1.0;
      const y = (evt.clientY / gl.canvas.height) * 2.0 - 1.0;
      mouse = [x * s[0], -y * s[1]];
    }, false);*/

    document.querySelectorAll('.js-kvText').forEach(elem => {
      elem.innerHTML = elem.textContent.replace(/\S/g, '<span class="letter">$&</span>')
    })


    const render = (time) => {
      twgl.resizeCanvasToDisplaySize(gl.canvas);
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      const uniforms = {
        time: time * 0.001,
        mouse: mouse,
        fade: this.item.fade,
        ringS: this.item.ringS,
        ringC: this.item.ringC,
        ringScale: this.item.ringScale,
        resolution: [gl.canvas.width, gl.canvas.height],
        heightTexture: textures.heightTexture
      };

      gl.useProgram(programInfo.program);
      twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo);
      twgl.setUniforms(programInfo, uniforms);
      twgl.drawBufferInfo(gl, bufferInfo);

      requestAnimationFrame(render);
    }

    requestAnimationFrame(render);
    this.timeline.play();
  }

  loadShader(pathArray) {
    if(Array.isArray(pathArray) !== true) {
      throw new Error('invalid argument');
    }
    const promises = pathArray.map((path) => {
      return fetch(path).then((response) => {
        return response.text();
      })
    });
    return Promise.all(promises);
  }


}
