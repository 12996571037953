import EventEmitter from 'events';

export default class HandleMediaQuery extends EventEmitter {
  constructor(minWidth) {
    super();
    this.media = '';
    this.minWidth = (minWidth) ? minWidth : '769';
    this.mql = `(min-width: ${this.minWidth}px)`
    this.matchMedia = window.matchMedia(this.mql);
    this.init()
  }

  init() {

    window.addEventListener('resize', ()=>{
      this.change()
    });
  }

  change() {
    if(this.matchMedia.matches) {
      // 769px以上の場合の処理
      if(this.media !== 'PC') {
        // console.log(`${this.minWidth}px以上`);
        this.emit("mediaQueryChange", 'PC');
        this.media = 'PC'
      }
    } else {
      // 769px未満の場合の処理
      if(this.media !== 'SP') {
        // console.log(`${this.minWidth}px未満`);
        this.emit("mediaQueryChange", 'SP');
        this.media = 'SP'
      }
    }
  }


}
