import anime from 'animejs'
import PerfectScrollbar from 'perfect-scrollbar';
import ScrollMagic from 'scrollmagic';
import 'debug.addIndicators'
import HomeKv from './home-kv'
import HandleMediaQuery from "./handleMediaQuery"

let home, handleMediaQuery;


export default class Home {
  constructor() {
    this.naviW = 40;
    this.headerW = 125;
    this.windowW = 0;
    this.isMedia = '';
    // this.naviAreaEl = document.querySelector('#navi');
    this.naviAreaEl = document.querySelectorAll('.naviArea')
    this.accordionAreaEl = document.querySelectorAll('.accordionArea')
    this.containerEl = document.querySelector("#container");
    this.containerWrapEl = document.querySelector("#container > .wrap");
    this.panelsEl = document.querySelectorAll("section.panel");
    this.pcAnimeEl = document.querySelectorAll(".js-animePc");
    this.timeoutId = null;
    this.scenes = []
    this.clickFlg = false
    this.init();
  }

  /**
   * PC用 anime タイムラインの破棄
   * @returns {Promise<void>}
   */
  async removeAnimation() {
    anime.remove('#container');
    for(let i = 0; i < this.pcAnimeEl.length; i++) {
      this.pcAnimeEl[i].removeAttribute('style');
    }
  }

  /**
   * PC用 anime タイムラインの作成
   * @returns {Promise<void>}
   */
  async initAnimation() {

    const defaultOption = {
      autoplay: false,
      duration: 1000,
      // loop: true,
      // direction: 'alternate',
    }

    const slidIn = {
      opacity: [0, 1],
      translateX: [50, 0],
      easing: 'easeOutBack',
      duration: 1000,
      delay: function(el, i, l) {
        return (i + 1) * 150;
      },
    }

    const fadeIn = {
      opacity: [0, 1],
      scale: [0.95, 1],
      easing: 'easeOutBack',
      duration: 1000,
      delay: function(el, i, l) {
        return (i + 1) * 150;
      },
    }

    /**
     * philosophy エリア
     */
    this.setScene('.js-philosophy_anime_text01', anime({
      targets: '.js-philosophy_anime_text01',
      ...defaultOption,
      ...slidIn
    }))

    this.setScene('.js-philosophy_anime_text02', anime({
      targets: '.js-philosophy_anime_bg',
      ...defaultOption,
      scale: [1.2, 1],
      translateX: ['-10%', '0%'],
      duration: 1000,
      easing: 'easeOutCubic'
    }))

    this.setScene('.js-philosophy_anime_text02', anime({
      targets: '.js-philosophy_anime_text02',
      ...defaultOption,
      ...slidIn
    }))

    /**
     * whoWeAre エリア
     */
    this.setScene('.js-whoWeAre_anime_text01', anime({
      targets: '.js-whoWeAre_anime_text01',
      ...defaultOption,
      ...slidIn
    }))
    this.setScene('.js-whoWeAre_anime_bg01_trg', anime({
      targets: '.js-whoWeAre_anime_bg01',
      ...defaultOption,
      scale: [2, 1],
      translateX: ['-10%', '0%'],
      easing: 'easeOutCubic'
    }))

    this.setScene('.js-whoWeAre_anime_text02', anime({
      targets: '.js-whoWeAre_anime_text02',
      ...defaultOption,
      ...slidIn
    }))
    // this.setScene('.js-whoWeAre_anime_bg02', anime({
    //   targets: '.js-whoWeAre_anime_bg02',
    //   ...defaultOption,
    //   // translateX: ['-20%', '0%'],
    //   easing: 'easeOutCubic',
    // }))

    this.setScene('.js-whoWeAre_anime_img03', anime({
      targets: '.js-whoWeAre_anime_bg02_blur',
      ...defaultOption,
      opacity: [0, 1],
      easing: 'easeInQuad',
    }))

    this.setScene('.js-whoWeAre_anime_text03', anime({
      targets: '.js-whoWeAre_anime_text03',
      ...defaultOption,
      ...slidIn
    }))

    this.setScene('.js-whoWeAre_anime_img03', anime({
      targets: '.js-whoWeAre_anime_img03 img',
      ...defaultOption,
      scale: [1.5, 1],
      opacity: [0, 1],
      easing: 'easeOutCubic'
    }))
    this.setScene('.js-whoWeAre_anime_btns', anime({
      targets: '.js-whoWeAre_anime_btns',
      ...defaultOption,
      ...fadeIn
    }))


    /**
     * News
     */
    this.setScene('.js-news_anime_text', anime({
      targets: '.js-news_anime_text',
      ...defaultOption,
      ...slidIn
    }))
    this.setScene('.js-news_anime_btns', anime({
      targets: '.js-news_anime_btns',
      ...defaultOption,
      ...fadeIn
    }))


    /**
     * Members
     */
    this.setScene('.js-members_anime_text', anime({
      targets: '.js-members_anime_text',
      ...defaultOption,
      ...slidIn
    }))
    this.setScene('.js-members_anime_btns', anime({
      targets: '.js-members_anime_btns',
      ...defaultOption,
      ...fadeIn
    }))

    /**
     * Gallery
     */
    this.setScene('.js-gallery_anime_text', anime({
      targets: '.js-gallery_anime_text',
      ...defaultOption,
      ...slidIn
    }))
    this.setScene('.js-gallery_anime_btns', anime({
      targets: '.js-gallery_anime_btns',
      ...defaultOption,
      ...fadeIn
    }))

  }

  setScene(_triggerElement, _anime) {
    const scene = new ScrollMagic.Scene(
      {triggerElement: _triggerElement}
    )
    .on("enter", (event) => {
      // console.log('enter')
      _anime.pause()
      _anime.direction = 'normal'
      _anime.restart()
    })
    .on("leave", (event) => {
      // console.log('leave')
      _anime.pause()
      _anime.direction = 'reverse'
      _anime.restart()
    })
    // .addIndicators() //検証用

    this.scenes.push(scene)
    this.controller.addScene(scene)
  }

  /**
   * スクロールマジックの削除
   */
  destroyScrollMagic() {
    if(this.controller) {
      this.controller = this.controller.destroy(true);
      this.scenes = [];
    }
  }

  /**
   * PC用 スクロールマジックとアニメーションの初期設定
   * @returns {Promise<void>}
   */
  async initScrollMagic() {
    this.destroyScrollMagic()
    await this.removeAnimation()

    this.controller = new ScrollMagic.Controller({
      container: '#container',
      vertical: false,
      globalSceneOptions: {
        triggerHook: 0.7,
      }
    });

    await this.initAnimation()

    /**
     * メンバーのシャッフルトリガー設定
     */
    const members = new ScrollMagic.Scene(
      {triggerElement: "#members"}
    )
    .on("enter", (event) => {
      // console.log('enter')
      this.memberShuffleSetInterval()
    })
    .on("leave", (event) => {
      // console.log('leave')
      this.memberShuffleCleaInterval()
    })
    // .addIndicators() //検証用

    this.scenes.push(members)
    this.controller.addScene(members)

    /**
     * ぎゃらりのシャッフルトリガー設定
     */
    const gallery = new ScrollMagic.Scene(
      {triggerElement: "#gallery"}
    )
    .on("enter", (event) => {
      // console.log('enter')
      this.galleryShuffleSetInterval()
    })
    .on("leave", (event) => {
      // console.log('leave')
      this.galleryShuffleCleaInterval()

    })
    // .addIndicators() //検証用

    this.scenes.push(gallery)
    this.controller.addScene(gallery)
  }

  /**
   * PC用 横スクロール用の width サイズ指定、横並びを削除
   * @returns {Promise<void>}
   */
  async resetSize() {
    this.containerWrapEl.style.display = ''
    this.containerWrapEl.style.flexWrap = ''
    this.containerWrapEl.style.width = ''
    this.containerWrapEl.style.overflow = '';

    for(let i = 0; i < this.panelsEl.length; i++) {
      this.panelsEl[i].style.width = ''
      this.panelsEl[i].style.minWidth = ''
    }
  }

  /**
   * plugin : PerfectScrollbar
   * PC用 横スクロール 生成
   */
  setPerfectScrollbar() {
    // カスタムスクロールバー
    this.container = document.querySelector('#container');
    this.ps = new PerfectScrollbar(this.container,
      {
        wheelSpeed: 0.8,
        useBothWheelAxes: true,
        suppressScrollY: true
      });
  }

  /**
   * PC用 横スクロール 破棄
   */
  destroyPerfectScrollbar() {
    if(this.ps) {
      this.ps.destroy();
      this.ps = null; // to make sure garbages are collected
    }
  }

  /**
   * SP用 アコーディオン open イベント
   * ターゲット要素以外は閉じる、
   * @param event
   */
  openAccordion(event) {
    console.log(event.target.dataset.area, this.clickFlg)
    if(this.clickFlg) {
      return false
    }
    this.clickFlg = true

    let target = null
    let scrollTop = 0
    if(!event.target.dataset.area) {
      target = event.target.parentNode.nextElementSibling
      scrollTop = event.target.parentNode.dataset.scrollTop
    } else {
      target = event.target.nextElementSibling
      scrollTop = event.target.dataset.scrollTop
    }

    // const target = event.target.nextElementSibling
    const num = target.dataset.num
    let animeHeight = target.dataset.clientHeight

    if(event.target.dataset.area === 'members') {
      this.memberShuffleSetInterval()
    }

    if(event.target.dataset.area === 'gallery') {
      this.galleryShuffleSetInterval()
    }

    if(target.clientHeight > 0) {
      animeHeight = 0
    }

    anime({
      targets: target,
      height: animeHeight,
      duration: 1000,
      easing: 'easeOutCubic'
    })

    anime({
      targets: ['body', 'html'],
      scrollTop: scrollTop - 30,
      duration: 1200,
      easing: 'easeOutCubic',
      complete: () => {
        this.clickFlg = false
      }
    })

    for(let i = 0; i < this.allEl.length; i++) {
      if(i != num) {
        anime({
          targets: this.allEl[i],
          height: 0,
          duration: 500,
          easing: 'easeOutCubic'
        })
      }
    }
  }

  /**
   * SP用 アコーディオンコンテンツの設定
   * .accordionBtn -> open ボタン
   * .accordionArea -> 開閉するコンテンツエリア
   * ボタン(.accordionBtn)の次の要素がコンテンツ(.accordionArea)になるように固定
   * .accordionBtn(data-scroll-top) -> ボタンの初期位置記録
   * .accordionArea(data-client-height) -> コンテンツの高さ記録
   * .accordionArea(data-num) -> コンテンツの要素の順番記録
   * @returns {Promise<void>}
   */
  async setAccordion() {
    await this.destroyAccordion()
    await this.resetSize()
    for(let i = 0; i < this.naviAreaEl.length; i++) {
      const parent = this.naviAreaEl[i]

      for(let c = 0; c < parent.children.length; c++) {
        const el = parent.children[c];

        if(el.classList.contains("accordionBtn")) {
          el.dataset.scrollTop = window.pageYOffset + el.getBoundingClientRect().top;
          el.addEventListener('click', this.openAccordion.bind(this))
        }

        if(el.classList.contains("accordionArea")) {
          el.dataset.clientHeight = el.clientHeight;
          el.dataset.num = i;
          anime.set(el, {
            height: 0,
            overflow: 'hidden',
            transformOrigin: ' top left'
          })
        }
      }
    }
  }

  /**
   * SP用 アコーディオンの解除
   * addEventListenerの削除
   * 各要素の高さ指定等のリセット
   * @returns {Promise<void>}
   */
  async destroyAccordion() {
    for(let i = 0; i < this.naviAreaEl.length; i++) {
      const parent = this.naviAreaEl[i]

      for(let c = 0; c < parent.children.length; c++) {
        const el = parent.children[c];

        if(el.classList.contains("accordionBtn")) {
          el.removeEventListener('click', this.openAccordion, false)
        }

        if(el.classList.contains("accordionArea")) {
          el.style.height = '';
          anime.set(el, {
            height: '',
            overflow: '',
            transformOrigin: ''
          })
        }
      }
    }
  }

  /**
   * リサイズイベント
   * PC、SPの切り替え
   */
  resizeEvent() {
    if(this.timeoutId !== false) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(async() => {
      switch(this.isMedia) {
        case 'PC':
          this.panelsEl[0].style.height = ''
          await this.resetSize()
          await this.destroyAccordion();
          let panelW = 0

          $('.member.js-members_anime_btns').css({
            width: '',
            height: '',
          })
          //450 658
          setTimeout(()=>{
            $('.member.js-members_anime_btns').css({
              width: document.querySelector('.member.js-members_anime_btns').clientHeight * 0.683890578,
              height: document.querySelector('.member.js-members_anime_btns').clientHeight,
            })
          },1)


          for(let i = 0; i < this.panelsEl.length; i++) {
            let elemW = this.panelsEl[i].clientWidth
            elemW -= this.naviW * ( this.panelsEl.length - 2 )
            panelW += elemW;
            // console.log(elemW,this.panelsEl[i].clientWidth,this.panelsEl[i])
            this.panelsEl[i].style.width = elemW + 'px'
            this.panelsEl[i].style.minWidth = 'auto'
            if(i >= this.panelsEl.length - 1) {
              this.containerWrapEl.style.display = 'flex'
              this.containerWrapEl.style.flexWrap = 'wrap'
              this.containerWrapEl.style.width = panelW + 'px'
              this.containerWrapEl.style.overflow = 'hidden';
              if(this.ps) {
                this.ps.update();
                this.initScrollMagic();
              }
            }
          }
          // 処理内容

          break
        case 'SP':
          $('.member.js-members_anime_btns').css({
            width: '',
            height: '',
          })
          if(this.windowW !== window.innerWidth) {
            this.panelsEl[0].style.height = window.innerHeight + 'px'
            await this.setAccordion()
          }
          this.windowW = window.innerWidth;
          break
        default:
          break
      }
      this.timeoutId = null;
    }, 300);
  }


  /**
   * 最初の処理
   */
  init() {
    const kv = new HomeKv();

    this.allEl = document.querySelectorAll('.accordionArea')

    /**
     * メンバー紹介のランダム表示
     */
    this.memberAllListElements = document.querySelectorAll('.js-members_all_list')
    this.memberBoxElements = document.querySelectorAll('.js-members_box')
    this.memberCopyElements = []
    this.memberShuffleTimer = null

    this.memberAllListElements.forEach((el) => {
      this.memberCopyElements.push(el.cloneNode(true));
      el.style.display = 'none'
    })

    this.memberSetInit()

    /**
     * ギャラリーのランダム表示
     */
    this.galleryAllListElements = document.querySelectorAll('.js-gallery_all_list')
    this.galleryBoxElements = document.querySelectorAll('.js-gallery_box')
    this.galleryCopyElements = []
    this.galleryShuffleTimer = null

    this.galleryAllListElements.forEach((el) => {
      this.galleryCopyElements.push(el.firstElementChild.cloneNode(true));
      el.style.display = 'none'
    })

    this.gallerySetInit()

    /**
     * リサイズイベントリスナー
     */
    const resize = () => {
      this.resizeEvent();
    }
    window.addEventListener("resize", resize);

    /**
     * requestAnimationFrame
     * updateScreen
     * PCでのナビゲーション追跡用
     */
    const updateScreen = () => {
      switch(this.isMedia) {
        case 'PC':
          let ww = $('#container').width()
          for(let i = 0; i < this.naviAreaEl.length; i++) {
            const name = this.naviAreaEl[i].dataset.area
            const xPint = this.xPositionHelper($(`.${name}`))
            const $e = $(`.navi_${name}`)
            let left = xPint
            if(left < (this.naviW * i)) {
              left = (this.naviW * i)
            } else if(left > (ww - (this.naviW * (this.naviAreaEl.length - i)))) {
              left = ww - (this.naviW * (this.naviAreaEl.length - i))
            }
            $e.css({
              'left': left
            })
          }
          break
        case 'SP':

          break
        default:
          break
      }
      requestAnimationFrame(updateScreen);
    }
    requestAnimationFrame(updateScreen);

    /**
     * OPスキップ機能
     */
    let skip = false
    document.querySelector('.opSkip').addEventListener('click', () => {
      if(skip) {
        return false
      }
      skip = true;
      document.querySelector('#loading').style.zIndex = ''
      anime({
        targets: '.opSkip',
        opacity: 0,
        duration: 300,
        easing: 'easeOutBack',
        complete: function(anim) {
          document.querySelector('.opSkip').style.display = 'none'
        }
      })
      anime({
        targets: '#loading',
        opacity: [0, 1],
        duration: 1000,
        delay: 10,
        easing: 'easeOutBack',
        begin: function() {
        },
        complete: function(anim) {
          kv.seekEndAnime()
          anime({
            targets: '#loading',
            opacity: 0,
            duration: 400,
            easing: 'easeOutBack',
            complete: function(anim) {
              document.querySelector('#loading').style.zIndex = '-1'
            }
          })
        }
      })

    })

    /**
     * 初回のみOPアニメーションが走るように
     */
    // console.log(document.referrer, document.domain, !document.referrer.match(document.domain))
    if(document.referrer.match(document.domain)) {
      document.querySelector('.opSkip').style.display = 'none'
      anime({
        targets: '#loading',
        opacity: 0,
        duration: 300,
        delay: 300,
        easing: 'easeOutBack',
        complete: function(anim) {
          document.querySelector('#loading').style.zIndex = '-1'
        }
      })
      kv.seekEndAnime()
    } else {
      document.querySelector('#loading').style.opacity = 0
      document.querySelector('#loading').style.zIndex = '-1'
      kv.init()
    }

    /**
     * PCの 帯にアンカーリンク機能追加
     */
    const goToAreaEl = document.querySelectorAll('.js-goToArea')
    const targetCont = document.querySelector('#container')
    goToAreaEl.forEach((elem, i) => {
      const targetElement = document.getElementById(elem.dataset.area)
      const sideMargin = this.headerW + (this.naviW * ((goToAreaEl.length - 1) - i))
      elem.addEventListener('click', () => {
        const clientRect = targetElement.getBoundingClientRect();
        const px = window.pageXOffset + clientRect.left + targetCont.scrollLeft - sideMargin;
        // console.log(px)
        anime({
          targets: targetCont,
          scrollLeft: px,
          duration: 1000,
          easing: 'easeOutCubic'
        })
      })
    })


  }

  /**
   * メンバー初期値表示
   */
  memberSetInit(){
    this.memberBoxElements.forEach((el, i) => {
      el.innerHTML = ""
      el.appendChild(this.memberCopyElements[i])
    })
  }
  /**
   * ギャラリー初期値表示
   */
  gallerySetInit(){
    this.galleryBoxElements.forEach((el, i) => {
      el.innerHTML = ""
      el.appendChild(this.galleryCopyElements[i])
    })
  }

  /**
   * メンバーシャッフルのタイマーセット
   */
  memberShuffleSetInterval(){
    if(this.memberShuffleTimer){
      return false;
    }
    this.memberSetInit()

    const animeDefault = {
      easing: 'easeOutBack',
      duration: 800,
      delay: function(el, i, l) {
        return (i + 1) * 50;
      },
    }

    this.memberShuffleTimer = setInterval(() => {
      const shuffle = this.shuffle(this.memberCopyElements)
      anime({
        targets: this.memberBoxElements,
        scale: [1, 0.7],
        opacity: [1, 0],
        ...animeDefault,
        complete: (ani) => {
          // console.log(ani)
          this.memberBoxElements.forEach((el, i) => {
            el.innerHTML = ""
            el.appendChild(shuffle[i])
          })
          anime({
            targets: this.memberBoxElements,
            scale: 1,
            opacity: 1,
            ...animeDefault
          })
        }
      })

    }, 7000)
  }
  /**
   * ギャラリーシャッフルのタイマーセット
   */
  galleryShuffleSetInterval(){
    if(this.galleryShuffleTimer){
      return false;
    }
    this.gallerySetInit()

    const animeDefault = {
      easing: 'easeOutBack',
      duration: 800,
      delay: function(el, i, l) {
        return (i + 1) * 50;
      },
    }

    this.galleryShuffleTimer = setInterval(() => {
      const shuffle = this.shuffle(this.galleryCopyElements)
      anime({
        targets: this.galleryBoxElements,
        scale: [1, 0.7],
        opacity: [1, 0],
        ...animeDefault,
        complete: (ani) => {
          // console.log(ani)
          this.galleryBoxElements.forEach((el, i) => {
            el.innerHTML = ""
            el.appendChild(shuffle[i])
          })
          anime({
            targets: this.galleryBoxElements,
            scale: 1,
            opacity: 1,
            ...animeDefault
          })
        }
      })

    }, 7000)
  }

  memberShuffleCleaInterval(){
    if(this.memberShuffleTimer){
      clearInterval(this.memberShuffleTimer)
      this.memberShuffleTimer = null
    }
  }

  galleryShuffleCleaInterval(){
    if(this.galleryShuffleTimer){
      clearInterval(this.galleryShuffleTimer)
      this.galleryShuffleTimer = null
    }
  }

  /**
   * 配列Shuffle用
   * @param array {Array}
   * @returns {*}
   */
  shuffle([...array]) {
    for(let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  /**
   * メディアクエリ切り替え
   * @param media {String} 'PC'or'SP'
   * @constructor
   */
  mediaQuerySwitch(media) {
    // console.log(media)
    this.isMedia = media
    this.resizeEvent();
    this.memberShuffleCleaInterval()
    this.galleryShuffleCleaInterval()
    switch(media) {
      case 'PC':
        this.setPerfectScrollbar()
        anime.set('.isPc', {
          display: ""
        })
        anime.set('.isSp', {
          display: "none"
        })
        break
      case 'SP':
        this.destroyScrollMagic()
        this.destroyPerfectScrollbar()
        this.removeAnimation()
        anime.set('.isPc', {
          display: "none"
        })
        anime.set('.isSp', {
          display: ""
        })
        break
      default:
        break
    }
  }

  /**
   * #container 左からの位置取得ヘルパー
   * PCのナビゲーション用
   * @param $elm
   * @returns {*}
   */
  xPositionHelper($elm) {
    const ww = $('#container').width()
    const t = $elm.offset().left; // ターゲットの位置取得
    const p = t - ww - this.headerW;  // 画面左からのターゲットの位置
    return ww + p
  }
}

window.addEventListener('load', () => {
  home = new Home();
  handleMediaQuery = new HandleMediaQuery();
  handleMediaQuery.on('mediaQueryChange', (isMedia) => {
    home.mediaQuerySwitch(isMedia)
  });
  handleMediaQuery.change()
})
